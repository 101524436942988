<template>
  <v-dialog
    v-model="dialog"
    content-class="stake-tokens solana-modal"
    @click:outside="close"
    eager
    overlay-opacity="1"
  >
    <div class="solana-modal-close" @click="close"></div>

    <div class="stake-tokens-title solana-title">
      {{ $t('solana.claim_rewards_and_withdraw') }}
    </div>

    <div class="stake-tokens-body">
      <staking-input :options="input_options" light/>
      <div class="stake-tokens-actions">
        <app-button class="no-shadow btn-solana w-100">
          {{ $t('solana.claim_rewards') }}
        </app-button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import AppButton from '@/components/Button';
import StakingInput from '@/components/Solana/StakingInput';

export default {
  name: 'ClaimRewardsModal',
  components: { StakingInput, AppButton },
  props: {
    value: Boolean,
  },

  data() {
    return {
      dialog: false,
      input_options: {
        labels: {
          left: 'solana.input',
          right: 'solana.available',
        },
      },
    };
  },

  methods: {
    close() {
      this.$emit('input', false);
    },
  },

  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
  },
};
</script>

<style lang="scss">
</style>
