<template>
  <main class="solana-page">
    <div class="solana-pool-switch">
      <div class="solana-title"> Stake </div>
      <span class="solana-pool-switch-separator solana-title">/</span>
      <s-select
        v-model="pool"
        :options="cards"
        value_field="name"
        custom_trigger
        custom_option
        style="min-width: 127px"
        light
      >
        <template v-slot:trigger="{item}">
          <img :src="require(`@/assets/img/solana/cards/${item.image}`)" :alt="item.name">
          <span>{{ item.name }}</span>
        </template>

        <template v-slot:option="{option}">
          <img :src="require(`@/assets/img/solana/cards/${option.image}`)" :alt="option.name">
          <span>{{ option.name }}</span>
        </template>
      </s-select>
    </div>

    <go-back label="solana.back_to_pools" url="/cardano" light />

    <section class="reward light">
      <div class="reward-left">
        <div class="reward-header">
          <div>
            <div class="light-text2"> {{ $t('solana.staking.tvl') }} </div>
            <div class="light-text">$ 157M</div>
          </div>
          <div>
            <div class="light-text2"> {{ $t('solana.staking.farming_apy') }} </div>
            <div class="light-text">10%</div>
          </div>

          <div class="reward-header-icon">
            <img src="@/assets/img/solana/sht-light.png" alt="eth">
          </div>
        </div>

        <div class="reward-left-title">
          {{ $t('solana.staking.deposit_single_token') }}
        </div>
        <div class="reward-status d-flex justify-center">
          <div class="reward-status-active">{{ $t('solana.status.active') }} </div>
        </div>

        <div class="reward-items">
          <div class="d-flex justify-space-between">
            <div class="light-text2">{{ $t('solana.total_pool_deposits') }}</div>
            <div class="light-text">80,325 SHT</div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="light-text2">{{ $t('solana.total_rewards') }}</div>
            <div class="light-text">{{ $t('solana.unavailable') }}</div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="light-text2">{{ $t('solana.rewards_remaning') }}</div>
            <div class="light-text">28,312.22 SHT</div>
          </div>
        </div>

        <div class="reward-info">
          <div class="reward-info-block">
            <div class="light-text2">{{ $t('solana.rewards_type') }}</div>
            <div class="light-text">Single</div>
          </div>
          <div class="reward-info-block">
            <div class="light-text2">{{ $t('solana.pool_type') }}</div>
            <div class="light-text">{{ $t('solana.limited_stake') }}</div>
          </div>
          <div class="reward-info-block disable">
            <div class="light-text2">{{ $t('solana.time_to_deposit') }}</div>
            <div class="light-text">-</div>
          </div>
          <div class="reward-info-block disable">
            <div class="light-text2">{{ $t('solana.max_pool_size') }}</div>
            <div class="light-text">-</div>
          </div>
          <div class="reward-info-block disable">
            <div class="light-text2">{{ $t('solana.start_date') }}</div>
            <div class="light-text">-</div>
          </div>
          <div class="reward-info-block disable">
            <div class="light-text2">{{ $t('solana.end_date') }}</div>
            <div class="light-text">-</div>
          </div>
        </div>

      </div>

      <div class="reward-right">
        <div class="reward-right-content">
          <div class="reward-right-title">{{ $t('solana.your_stake') }}</div>

          <div class="reward-right-item d-flex align-center justify-space-between">
            <div>
              <div class="light-text2">{{ $t('solana.staked') }}</div>
              <div>SHT</div>
            </div>
            <div>
              <div class="d-flex align-center">
                -
                <img src="@/assets/img/solana/sht-light.png" alt="eth">
              </div>
            </div>
          </div>
          <div class="reward-right-item d-flex align-center justify-space-between">
            <div>
              <div class="light-text2">{{ $t('solana.rewards') }}</div>
              <div>SHT</div>
            </div>
            <div>-</div>
          </div>
          <div class="reward-right-item d-flex align-center justify-space-between">
            <div>
              <div class="light-text2">{{ $t('solana.available_to_claim') }}</div>
              <div>SHT</div>
            </div>
            <div> - </div>
          </div>
          <div class="reward-right-item d-flex align-center justify-space-between">
            <div>
              <div class="light-text2">{{ $t('solana.claimed') }}</div>
              <div>SHT</div>
            </div>
            <div> - </div>
          </div>
          <div class="reward-right-item d-flex align-center justify-space-between disabled">
            <div>
              <div class="light-text2">{{ $t('solana.time_untill_withdrawal') }}</div>
              <div>-:-:-:-</div>
            </div>
            <div>  </div>
          </div>

          <div class="reward-right-grid">
            <div
              class="reward-right-column d-flex align-center justify-center text-center pink"
              @click="reward_modal = true"
            >
              <div class="light-text">{{ $t('solana.stake') }}</div>
              <div class="light-text2">SHT</div>
            </div>
            <div
              class="reward-right-column d-flex align-center justify-center text-center"
              @click="claim_modal = true"
            >
              <div class="light-text">{{ $t('solana.exit_from_stake') }}</div>
              <div class="light-text2"> {{ $t('solana.withdraw_and_claim') }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <deposit-modal v-model="reward_modal" />
    <claim-rewards-modal v-model="claim_modal" />

  </main>
</template>

<script>
import GoBack from '@/components/Solana/GoBack';
import SSelect from '@/components/Solana/SSelect';
import DepositModal from '@/components/Cardano/DepositModal';
import ClaimRewardsModal from '../../components/Cardano/ClaimRewardsModal';

export default {
  name: 'CardanoPool',
  components: {
    ClaimRewardsModal,
    DepositModal,
    SSelect,
    GoBack,
  },
  data() {
    return {
      items: ['Single', 'Pair'],
      type: 'Single',
      reward_modal: false,
      claim_modal: false,
      pool: {
        image: '2.png',
        name: 'SHT',
      },
      cards: [
        {
          image: '1.png',
          name: 'SHT/ETH',
        },
        {
          image: '2.png',
          name: 'SHT',
        },
        {
          image: '3.png',
          name: 'SHT',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
</style>
